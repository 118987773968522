import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is our monthly free workout so bring a friend! 8:00 & 9:00am
at The Ville and 10:00 & 11:00am at East.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`There will be no CrossFit Kids this Saturday.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The CrossFit Open starts February 23rd! Sign up now at:
Games.crossfit.com.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      